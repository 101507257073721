body {
  background: #F0F2F5;

}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.truncate-overflow-body2 {
  position: relative;
  overflow: hidden;
  max-height: calc(1.43em * 4);
}
.truncate-overflow-body2:after {
  content: "";
  text-align: right;
  position: absolute;
  top: calc(1.43em * 3);
  right: 0;
  width: 30%;
  height: 1.43em;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
}